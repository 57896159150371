@import '~bootstrap/scss/bootstrap';

.table-sortable:hover {
  @extend .text-primary;
  cursor: pointer;
}

.table-sortable-active{
  @extend .text-primary
}

table.vertical-align > tbody > tr > td {
  vertical-align: middle;
}

.bg-light{
  background-color:#f4f6f8 !important;
}


.sidebar-nav{
  @extend .text-muted
}
.sidebar-nav:hover{
  @extend .text-dark
}


.card-header{
  @extend .card-header;
  border-bottom: 0px;
}

.editorClassName{
  min-height: 300px !important;
  border: 1px solid #ccc;
  padding:4px;
}

.text-sm {
  font-size: small;
}

.CodeMirror{
  height:600px !important;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
